import { Injectable } from '@angular/core';
import { LangService } from 'src/app/shared/lang.service';
import { hlpDate } from 'src/app/_models';
import { RepositoryService } from 'src/app/_services/repository.service';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(
    private RepositoryService: RepositoryService,
    private langService: LangService,) {

  }

  
  public cGuid(input: any): string {
    if (this.CheckValidGUID(input)) {
      return String(input);
    }
    else {
      return environment.EmptyGUID;
    }
  }

  
  public CheckValidGUID(input: any): boolean {
    if (input && input != null && input != environment.EmptyGUID && String(input).length == 36) {
      var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (pattern.test(String(input))) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  
  async SetGlobalPermission(ComponentName: string, UserRoleGUID: string = environment.EmptyGUID): Promise<string[]> {
    var ToReturn: string[] = []; 
    await this.RepositoryService.getAllAsync('ComponentPermission/GetGlobal(ComponentName=\'' + ComponentName + '\',UserRoleGUID=' + UserRoleGUID + ')')
      .then((res) => {
        ToReturn= res['value'] as string[];

      }).catch(
        (error) => {
          ToReturn= [];
        }
      );
    return ToReturn;
  }

  async SetBusinessPermisson(ComponentName: string, UserRoleGUID: string = environment.EmptyGUID): Promise<string[]> {
   var ToReturn: string[] = []; 
    await this.RepositoryService.getAllAsync('ComponentPermission/GetBussiness(ComponentName=\'' + ComponentName + '\',UserRoleGUID=' + UserRoleGUID+')')
      .then((res) => {
        ToReturn =  res['value'] as string[];

      }).catch(
        (error) => {
          ToReturn =  [];
        }
      );
    return ToReturn;
  }

  GetPermissionValue(PermissionList: string[], KeyParam: string): boolean {
    return PermissionList.includes(KeyParam);
  }

  //TODO: Move to base service
  refreshPage(): void {
    window.location.reload();
  }
  //TODO: Move to base service
  async ConfirmDialog(Title = '',
    MessageBody = '',
    Icon: SweetAlertIcon = 'warning') {

    const Yes = 'بله';
    const Cancel = 'خیر';
let Result = false;
    await Swal.fire({
      title: Title,
      text: MessageBody,
      icon: Icon,
      showCancelButton: true,
      confirmButtonText: Yes,
      cancelButtonText: Cancel
    }).then((Continue) => {
      if (Continue.value) {
        Result= true;
      }
      else {
        Result= false;
      }
    });
    return Result;
  }


  async Get_LastDate(Date: hlpDate): Promise<string> {
    let returnList = '';
    await this.RepositoryService.getItemExteraAsync( 'General/Get_LastDate/' , Date)
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          
          return undefined;
        }
      );
    return returnList;
  }


  async Get_NextDate(Date: hlpDate): Promise<string> {
    let returnList = '';
    await this.RepositoryService.getItemExteraAsync( '/General/Get_NextDate/' , Date)
      .then((res) => {
        returnList = res['value'];
      }).catch(
        (error) => {
          
          return undefined;
        }
      );
    return returnList;
  }
  
  // let ObjectParam: ParameterKeyValues[] = [];
  // ObjectParam.push({ KeyName: 'component', KeyValue: 'BudgetType', isString: true });
  // ObjectParam.push({ KeyName: 'UserInvolvedCompanyComponentRoleGUID', KeyValue: UserInvolvedCompanyComponentRoleGUID, isString: false });
  // const ServiceParam = this.RepositoryService.ParametersConcat(ObjectParam);
  //ttt() {
  //  let ObjectParam: RepositoryParameter[] = [];
  //  ObjectParam = this.SetParamter2('asdfasdf', 'asfasdf', true, ObjectParam);
  //  ObjectParam = this.SetParamter2('asdfasdf', 'asfasdf', true, ObjectParam);
  //  const serviceParam = GeneralService.ParameterConcat(ObjectParam);


  //  ObjectParam.push(GeneralService.SetParamter('asdfasdf', 'asfasdf', true));
  //  ObjectParam.push(GeneralService.SetParamter('asdfasdf', 'asfasdf', true));
  //  const serviceParam = GeneralService.ParameterConcat(ObjectParam);
  //}

  public SetParamter2(keyName: string, keyValye: string, isString: boolean, PreList: RepositoryParameter[]): RepositoryParameter[] {
    let returnParam: RepositoryParameter = new RepositoryParameter();
    returnParam.KeyName = keyName;
    returnParam.KeyValue = keyValye;
    returnParam.isString = isString;
    PreList.push(returnParam)
    return PreList;
  }

  public SetParamter(keyName: string, keyValye: string, isString: boolean): RepositoryParameter {
    let returnParam: RepositoryParameter = new RepositoryParameter();
    returnParam.KeyName = keyName;
    returnParam.KeyValue = keyValye;
    returnParam.isString = isString;

    return returnParam;

  }

  public ParametersConcat(lst: RepositoryParameter[]): string {
    const ParamString = lst.map(x => x.KeyName + '=' + (x.isString ? '\'' : '') + x.KeyValue + (x.isString ? '\'' : '')).join(',');
    return ParamString;
  }
}

export class RepositoryParameter {

  KeyName: string;
  KeyValue: string;
  isString: boolean
}
