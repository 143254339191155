

export class SetterInformationDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    FactoryID: number;
    FactoryGuID: string;
    Day1: string;
    Day2: string;
    Day3: string;
    Day4: string;
    Day5: string;
    Day6: string;
    Day7: string;
    Day8: string;
    Day9: string;
    Day10: string;
    Day11: string;
    Day12: string;
    Day13: string;
    Day14: string;
    Day15: string;
    Day16: string;
    Day17: string;
    Day18: string;
    Day19: string;
    Day20: string;
    Day21: string;
    Zarib: string;
    FirstCycleEggCount: string;
    FirstCycleZarib: string;
    FirstCycleTotalZarib: string;
    InCycleEggCount: string;
    InCycleZarib: string;
    InCycleTotalZarib: string;
    EndCycleEggCount: string;
    EndCycleZarib: string;
    EndCycleTotalZarib: string;



}


export class List_SetterInformationDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    FactoryGuID: string;
    FactoryName: string;
    Day1: string;
    Day2: string;
    Day3: string;
    Day4: string;
    Day5: string;
    Day6: string;
    Day7: string;
    Day8: string;
    Day9: string;
    Day10: string;
    Day11: string;
    Day12: string;
    Day13: string;
    Day14: string;
    Day15: string;
    Day16: string;
    Day17: string;
    Day18: string;
    Day19: string;
    Day20: string;
    Day21: string;
    Zarib: string;
    FirstCycleEggCount: string;
    FirstCycleZarib: string;
    FirstCycleTotalZarib: string;
    InCycleEggCount: string;
    InCycleZarib: string;
    InCycleTotalZarib: string;
    EndCycleEggCount: string;
    EndCycleZarib: string;
    EndCycleTotalZarib: string;

}
export class PList_SetterInformationDto {
    Data: List_SetterInformationDto[];
    TotalCount: number;
}



