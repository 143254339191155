import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SetterInformationDto, List_SetterInformationDto, PList_SetterInformationDto } from './setter-information.model'



const serviceName = "SetterInformationService"


@Injectable({
  providedIn: 'root',
})
export class SetterInformationService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SetterInformationDto> {
    let returnObject = new SetterInformationDto()
    await this.RepositoryService.getItemAsync('SetterInformation/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SetterInformationDto> {
    let returnObject = new SetterInformationDto()
    await this.RepositoryService.getItemAsync('SetterInformation/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_SetterInformation) => {
          returnObject = _SetterInformation;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(FinalCostCycleOfficeGUID: string , pagingString = ''): Promise<PList_SetterInformationDto> {
    let returnList = new PList_SetterInformationDto();
    await this.RepositoryService.getAllAsync('SetterInformation/Get_AllCustom(FinalCostCycleOfficeGUID='+FinalCostCycleOfficeGUID+')' + pagingString )
      .then((res) => {
        returnList.Data = res['value'] as List_SetterInformationDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  



  async Get_AllList(pagingString = ''): Promise<PList_SetterInformationDto> {
    let returnList = new PList_SetterInformationDto();
    await this.RepositoryService.getAllAsync('SetterInformation/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SetterInformationDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_SetterInformationDto: SetterInformationDto): Promise<SetterInformationDto> {
    let returnvalue: SetterInformationDto = null;
    await this.RepositoryService.createAsync('SetterInformation/Create_Custom', _SetterInformationDto)
      .then((res) => {
        returnvalue = res as SetterInformationDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_SetterInformationDto: SetterInformationDto): Promise<SetterInformationDto> {
    let returnvalue: SetterInformationDto = null;
    await this.RepositoryService.updateAsync('SetterInformation/Update_Custom', _SetterInformationDto)
      .then((res) => {
        returnvalue = res as SetterInformationDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('SetterInformation/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Get_AllSetterInformation(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<boolean> {
    let returnList: boolean = false;
    await this.RepositoryService.getItemAsync('SetterInformation/Get_AllSetterInformation(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }
}
